import React, { useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ExamTypeMenu from "./ExamTypeMenu";
import { getExamType } from "../../helpers/userCheck";

// Exam type selector in navbar
function ExamTypeIcon(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // Open/close drop down menu
  function openMenu(event) {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  return (
    <div className={classes.wrapper} onClick={openMenu}>
      <Typography noWrap variant="h6" className={classes.title}>
        {getExamType(props.user)}
      </Typography>
      <ArrowDropDownIcon className={classes.icon} />
      {/* Drop down menu */}
      <ExamTypeMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    cursor: "pointer",
    minWidth: "10rem",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    "&:hover ": {
      opacity: theme.customVars.hoverOpacity,
    },
  },
  icon: {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "0.25rem 0.5rem",
    color: "black",
  },
  title: {
    fontWeight: "bold",
    margin: "0.25rem 0",
    marginLeft: "1rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginRight: "0.5rem",
    },
  },
}));

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ExamTypeIcon);
